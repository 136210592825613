import * as React from 'react';
import classNames from 'classnames';
import { Download } from '@phosphor-icons/react';

import { formatDateShort } from '../../../utils/helpers/date';
import { getCurrencyById } from '../../../utils/helpers/currency';
import { RiskLevel } from '../../../utils/constants/constants';

import { Button } from '../../../ui';
import AppliedFilters from '../../../ui/components/Badges/AppliedFiltersBadge/AppliedFiltersBadge';

import { IFilters, defaultTransactionListFilters } from './TransactionListFilter';

interface Props {
  selectedText: string;
  count: number;
  filters: IFilters;
  setFilters: (filters: IFilters) => void;
  selectedTransactions: number[];
  setSelectedTransactions: (transactions: number[]) => void;
  isAllTransactionSelected: boolean;
  setIsAllTransactionSelected: (boolean) => void;

  selectAllTransactions: (boolean) => void;

  downloadSelectedTransactions: () => void;
}

const TransactionListAppliedFilter: React.FC<Props> = ({
  selectedText,
  count,
  filters,
  setFilters,
  selectedTransactions,
  setSelectedTransactions,
  isAllTransactionSelected,
  setIsAllTransactionSelected,

  selectAllTransactions,

  downloadSelectedTransactions,
}) => {
  const isFilterApplied = React.useMemo(() => {
    if (filters.risk_level?.length) return true;
    if (filters.currency?.length) return true;
    if (filters.created_start_date) return true;
    if (filters.created_end_date) return true;
    if (filters.start_date) return true;
    if (filters.end_date) return true;
    if (filters.start_date) return true;
    if (filters.end_date) return true;
    if (filters.transaction_start_date) return true;
    if (filters.transaction_end_date) return true;
    if (filters.rule_name?.length) return true;
  }, [filters]);

  const resetFilter = () => {
    setFilters(defaultTransactionListFilters);
  };

  return (
    <div
      className={classNames(
        'flex justify-stretch border-t-[1px] bg-gray-50',
        isFilterApplied || selectedTransactions?.length ? 'px-2 py-2.5' : ''
      )}>
      <div className='flex w-full items-center justify-between'>
        <div className='flex flex-wrap items-center'>
          {filters.start_date && (
            <AppliedFilters
              onRemove={() => {
                setFilters({
                  ...filters,
                  start_date: null,
                  end_date: null,
                });
              }}>
              Date Updated: {formatDateShort(filters.start_date)} - {formatDateShort(filters.end_date)}
            </AppliedFilters>
          )}
          {filters.transaction_start_date && (
            <AppliedFilters
              onRemove={() => {
                setFilters({
                  ...filters,
                  transaction_start_date: null,
                  transaction_end_date: null,
                });
              }}>
              Transaction Date: {formatDateShort(filters.transaction_start_date)} -{' '}
              {formatDateShort(filters.transaction_end_date)}
            </AppliedFilters>
          )}
          {filters.created_end_date && (
            <AppliedFilters
              onRemove={() => {
                setFilters({
                  ...filters,
                  created_start_date: null,
                  created_end_date: null,
                });
              }}>
              Date Added: {formatDateShort(filters.created_start_date)} -{' '}
              {formatDateShort(filters.created_end_date)}
            </AppliedFilters>
          )}
          {filters.risk_level?.map((risk, i) => (
            <AppliedFilters
              key={i}
              onRemove={() => {
                setFilters({
                  ...filters,
                  risk_level: filters.risk_level.filter((r) => r !== risk),
                });
              }}>
              Risk: {RiskLevel.find((item) => item.value === risk).label}
            </AppliedFilters>
          ))}
          {filters.currency?.map((curr, i) => (
            <AppliedFilters
              key={i}
              onRemove={() => {
                setFilters({
                  ...filters,
                  currency: filters.currency.filter((c) => c !== curr),
                });
              }}>
              Blockchain: {getCurrencyById(curr).label}
            </AppliedFilters>
          ))}
          {filters.rule_name?.map((rule_name, i) => (
            <AppliedFilters
              key={i}
              onRemove={() => {
                setFilters({
                  ...filters,
                  rule_name: filters.rule_name.filter((r) => r !== rule_name),
                });
              }}>
              Rule Name: {rule_name}
            </AppliedFilters>
          ))}
        </div>
        {isFilterApplied && (
          <div className='flex items-center'>
            <button className='mr-2 text-2xs text-blue-600' onClick={() => resetFilter()}>
              Reset Filters
            </button>
          </div>
        )}
      </div>
      {selectedTransactions?.length > 0 && (
        <div className='ml-auto flex min-w-[600px] items-center justify-end gap-3 pr-4 text-2xs'>
          <div></div>
          <div>{selectedText}</div>
          {!isAllTransactionSelected && (
            <button
              className='text-blue-500'
              onClick={() => {
                selectAllTransactions(true);
                setIsAllTransactionSelected(true);
              }}>
              Select all {count} transactions
            </button>
          )}
          <button
            className='text-blue-500'
            onClick={() => {
              setSelectedTransactions([]);
              setIsAllTransactionSelected(false);
            }}>
            Clear selection
          </button>
          <Button variant='tertiary' onClick={() => downloadSelectedTransactions()}>
            <Download size={17} />
          </Button>
        </div>
      )}
    </div>
  );
};

export default TransactionListAppliedFilter;
