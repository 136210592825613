/* eslint-disable no-console */
import { BrowserRouter } from 'react-router-dom';
import { FC, useCallback } from 'react';
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
  useQueryErrorResetBoundary,
} from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { AppRoutes } from '../routes';
import { AuthProvider, Auth0ProviderWithRedirect } from '../modules/auth';
import AxiosWrapper from './AxiosWrapper';
import 'react-loading-skeleton/dist/skeleton.css';
import { SkeletonTheme } from 'react-loading-skeleton';
import { Toast } from '../ui/components/Toast';
import { RouterContextProvider } from '../modules/router/RouterProvider';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorPage from '../components/ErrorPage/ErrorPage';
import { AxiosError } from 'axios';
import * as Sentry from '@sentry/react';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // refetchOnMount: false,
      // retryOnMount: false,
      staleTime: 0,
      refetchOnWindowFocus: false,
      retry: import.meta.env.NODE_ENV !== 'production' ? false : 2,
      useErrorBoundary(error: AxiosError) {
        const responseCode = error?.response?.status;
        if ([401, 403].includes(responseCode)) return true;
      },
      // retry: 0,
      // refetchOnWindowFocus: false,
      // cacheTime: 1000 * 60 * 10,
      // staleTime: 0,
      // staleTime: 1000 * 60 * 10,
    },
  },
  queryCache: new QueryCache({
    onError: (error: AxiosError, variables) => {
      try {
        Sentry.withScope((scope) => {
          scope.setExtra('variables', variables);
          scope.setExtra('endpoint', error?.config?.url);
          scope.setExtra('requestMethod', error?.config?.method);
          scope.setLevel('error');
          scope.setTag('errorType', 'queryCache');
          scope.setTag('statusCode', error?.response?.status?.toString());

          // Group similar API errors together
          const errorName = `${error?.config?.method} ${error?.response?.status}`;
          const errorMessage = `API Error: ${error?.config?.url?.split('?')[0]}`; // Remove query params for better grouping

          const errorEvent = new Error(errorMessage);
          errorEvent.name = errorName;
          errorEvent.stack = error.stack;

          Sentry.captureException(errorEvent, {
            fingerprint: [
              '{{ default }}',
              error?.config?.method || 'unknown_method',
              String(error?.response?.status || 'unknown_status'),
              error?.config?.url?.split('?')[0] || 'unknown_url',
            ],
            extra: {
              originalError: error,
              errorResponse: error?.response?.data,
            },
          });
        });
      } catch {
        Sentry.captureException(error);
      }
    },
  }),
  mutationCache: new MutationCache({
    onError: (error: AxiosError, variables) => {
      try {
        Sentry.withScope((scope) => {
          scope.setExtra('variables', variables);
          scope.setExtra('endpoint', error?.config?.url);
          scope.setExtra('requestMethod', error?.config?.method);
          scope.setLevel('error');
          scope.setTag('errorType', 'mutationCache');
          scope.setTag('statusCode', error?.response?.status?.toString());

          // Create a more descriptive error name
          const errorName = `API Mutation Error (${error?.response?.status}): ${error?.config?.method} ${error?.config?.url}`;
          const errorEvent = new Error(errorName);
          errorEvent.stack = error.stack;
          Sentry.captureException(errorEvent, {
            extra: {
              originalError: error,
              errorResponse: error?.response?.data,
            },
          });
        });
      } catch {
        Sentry.captureException(error);
      }
    },
  }),
});

const SPA: FC = () => {
  const onSignOut = useCallback(() => {
    queryClient.clear();
    queryClient?.removeQueries({
      predicate: () => true,
    });
  }, []);
  const { reset } = useQueryErrorResetBoundary();

  return (
    <>
      <SkeletonTheme baseColor='#fff' highlightColor='#D1D5DB'>
        <BrowserRouter>
          <RouterContextProvider>
            <QueryClientProvider client={queryClient}>
              <Auth0ProviderWithRedirect>
                <AuthProvider onSignOut={onSignOut}>
                  <AxiosWrapper>
                    <ErrorBoundary onReset={reset} FallbackComponent={ErrorPage}>
                      <AppRoutes />
                      <Toast />
                      <ReactQueryDevtools initialIsOpen={false} position='bottom-right' />
                    </ErrorBoundary>
                  </AxiosWrapper>
                </AuthProvider>
              </Auth0ProviderWithRedirect>
            </QueryClientProvider>
          </RouterContextProvider>
        </BrowserRouter>
      </SkeletonTheme>
    </>
  );
};

export default SPA;
