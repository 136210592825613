import { FC, useState } from 'react';
import {
  useBillingCreditNonUniqueUsageGet,
  useBillingCreditUsageGet,
  useProfilePlanGet,
} from '../../../../api/billing';
import { TabHeader } from '../../../../components/ui/components/Header';
import CompassLoader from '../../../../components/ui/components/Loader/CompassLoader';
import { RadioButton, RadioButtons } from '../../../../ui';
import { formatMonthYear, formatOnlyDate } from '../../../../utils/helpers/date';
import Table from '../../../../ui/components/Table/Table';
import {
  buildInfiniteQueryTableProps,
  flattenInfiniteQueryResult,
} from '../../../../utils/helpers/react-query.helper';
import { format } from 'date-fns';
import Tab from '../../../../components/ui/components/Tab/Tab';
import BillingAppliedFilter from './BillingAppliedFilter';
import BillingFilter, { IFilters } from './BillingFilter';

interface BillingUsageProps {}

const BillingUsage: FC<BillingUsageProps> = () => {
  const usageTabs = [
    { label: 'Monthly', value: 'monthly' },
    { label: 'Daily', value: 'daily' },
  ];
  const [filters, setFilters] = useState<IFilters>({
    start_date: null,
    end_date: null,
  });
  const [usageTab, setUsageTab] = useState<'unique' | 'non-unique'>('non-unique');
  const [tab, setTab] = useState(0);
  // This is for month and year filter. (Currently hidden)

  // const months = listOfMonths().map((month, i) => {
  //   return {
  //     label: month,
  //     value: (i + 1).toString(),
  //   };
  // });
  // const [selectedMonth, setSelectedMonth] = useState<IOption>(months[0]);

  // const years = Array.from({ length: new Date().getFullYear() - 2019 + 1 }, (_, i) => {
  //   return {
  //     label: (new Date().getFullYear() - i).toString(),
  //     value: (new Date().getFullYear() - i).toString(),
  //   };
  // });
  // const [selectedYear, setSelectedYear] = useState<IOption>(years[0]);
  const { data: profilePlanResponse, isLoading: isPlanLoading } = useProfilePlanGet();

  const creditUsageQuery = useBillingCreditUsageGet(
    {
      interval: usageTabs[tab].value as 'monthly' | 'daily',
      start_date: filters.start_date,
      end_date: filters.end_date,
    },
    {
      enabled: usageTab === 'unique',
    }
  );
  const creditUsageNonUniqueQuery = useBillingCreditNonUniqueUsageGet(
    {
      interval: usageTabs[tab].value as 'monthly' | 'daily',
      start_date: filters.start_date,
      end_date: filters.end_date,
    },
    {
      enabled: usageTab === 'non-unique',
    }
  );

  const creditUsageResponse = creditUsageQuery?.data;
  const creditUsageNonUniqueResponse = creditUsageNonUniqueQuery?.data;

  const plan = profilePlanResponse?.data;

  if (isPlanLoading) {
    return (
      <div>
        <CompassLoader />
      </div>
    );
  }

  const onChangeRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUsageTab(e.target.value as 'unique' | 'non-unique');
  };

  const handleApply = (newFilters: IFilters) => {
    setFilters(newFilters);
  };

  const headerData = [
    tab === 1 ? 'Day' : 'Month',
    'Address Screenings',
    'Transaction Screenings',
    'Total Screenings',
  ];
  const [, creditUsageData] = flattenInfiniteQueryResult(creditUsageResponse);
  const [, creditUsageNonUniqueData] = flattenInfiniteQueryResult(creditUsageNonUniqueResponse);
  const rowsData = usageTab === 'unique' ? creditUsageData : creditUsageNonUniqueData;
  const rows = rowsData?.map((item, i) => {
    const intervalFormatted =
      usageTabs[tab].value === 'monthly'
        ? formatMonthYear(item?.month?.split(' ')[0])
        : format(new Date(item?.date_screened), 'PP');
    return {
      id: i,
      data: [
        intervalFormatted,
        item.addresses ?? 0,
        item.transactions ?? 0,
        item.addresses + (item.transactions ?? 0),
      ],
    };
  });

  const ValueWrapper = ({ title, value }) => {
    return (
      <div className=''>
        <p className='text-sm font-semibold leading-8 text-gray-500'>{title}</p>
        <p className='text-sm'>{value}</p>
      </div>
    );
  };

  const UsageWrapper = () => {
    return (
      <div className='ml-1'>
        <div className='mt-2 flex w-3/5 justify-between'>
          <ValueWrapper title='Subscription expires on' value={formatOnlyDate(plan.end_date)} />
          <ValueWrapper title='User Licenses' value={plan.user_limit} />
          <ValueWrapper
            title='API Credits (per annum)'
            value={plan.credit_limit ? plan.credit_limit : 'Not applicable'}
          />
        </div>
        <p className='py-4 text-xs text-gray-500'>
          1 Credit refers to 1 Merkle Science API call. Credits reset every 12 months
        </p>
      </div>
    );
  };

  return (
    <div className='h-full max-w-6xl'>
      <TabHeader heading={plan && `${plan.name}`} />
      {plan ? (
        <UsageWrapper />
      ) : (
        <p className='mb-2 px-1 text-center text-sm text-gray-700'>Error in loading Billing Plan</p>
      )}
      <hr className='mx-1 py-3' />

      <TabHeader heading='API Credit Usage Report' />
      <div className='flex justify-between'>
        <RadioButtons className='mt-4' name='interval' selected={usageTab} onChange={onChangeRadio}>
          <RadioButton value='non-unique' labelText='Total Screenings' />
          <RadioButton value='unique' labelText='Unique Screenings' />
        </RadioButtons>
        {/* This is for month and year filter. (Currently hidden) */}
        {/* <div className='flex'>
          <SingleSelect
            value={selectedMonth}
            options={months}
            isCloseHidden
            handleChange={(value) => setSelectedMonth(value)}
            className='mr-2 w-28'
          />
          <SingleSelect
            value={selectedYear}
            options={years}
            isCloseHidden
            handleChange={(value) => setSelectedYear(value)}
          />
        </div> */}
      </div>

      <Table
        title='Usage'
        border
        className='mt-4'
        headerData={headerData}
        rows={rows}
        isLoading={usageTab === 'unique' ? creditUsageQuery.isLoading : creditUsageNonUniqueQuery.isLoading}
        filterComponent={<BillingFilter filters={filters} onApply={handleApply} />}
        appliedFilters={<BillingAppliedFilter filters={filters} setFilters={setFilters} />}
        heightOffset={370}
        {...buildInfiniteQueryTableProps(
          usageTab === 'unique' ? creditUsageQuery : creditUsageNonUniqueQuery
        )}
        headerActionsLeft={<Tab values={usageTabs} type='primary' listClassName='' onChange={setTab} />}
      />
    </div>
  );
};

export default BillingUsage;
