import * as React from 'react';

import AddressSummary from './AddressSummary';
import CounterpartySummary from '../../AddressTransaction/CounterpartySummary';
import { IAddressResponse } from '../../../api/dtos/address';
import Alerts from '../../common/Alerts';
import InvestigationTree from '../../AddressTransaction/InvestigationTree/InvestigationTree';
import CurrencyDeprecation from '../../common/CurrencyDeprecationAlert';
import { Card } from '../../ui/components/Card';
// import StageAndAssignedWorkSpace from '../../common/StageAndAssignedWorkspace';
import { Transition } from '@headlessui/react';
import AlertDetails from '../../common/Alerts/Alert/AlertDetails';
import Skeleton from 'react-loading-skeleton';
import { useRouter } from '../../../modules/router/RouterProvider';

interface Props {
  address: IAddressResponse;
  balance: number;
  balance_usd: number;
  alertDetails: number;
  setAlerts: (alerts: number) => void;
  setAlertDetails: (alert: number) => void;
}

const Overview: React.FC<Props> = ({
  address,
  balance,
  balance_usd,
  alertDetails,
  setAlerts,
  setAlertDetails,
}) => {
  const { navigate } = useRouter();
  return (
    <>
      <Transition
        as='div'
        show={!!alertDetails}
        enter='transition-opacity duration-300'
        enterFrom='opacity-0'
        enterTo='opacity-100'
        leave='transition-opacity duration-150'
        leaveFrom='opacity-100'
        leaveTo='opacity-0'>
        {alertDetails ? (
          <AlertDetails
            alert={alertDetails}
            type='address'
            id={address.id}
            onClose={() => {
              navigate('', { alert: '' });
              setAlertDetails(null);
            }}
            address={address}
          />
        ) : (
          <Skeleton count={20} />
        )}
      </Transition>
      <Transition
        as='div'
        show={!alertDetails}
        enter='transition-opacity duration-300'
        enterFrom='opacity-0'
        enterTo='opacity-100'
        leave='transition-opacity duration-150'
        leaveFrom='opacity-100'
        leaveTo='opacity-0'>
        <div className='flex flex-col gap-3'>
          <CurrencyDeprecation currency={address.currency} />
          <AddressSummary address={address} balance={balance} balance_usd={balance_usd} />
          <Alerts id={address.id} type='address' setAlerts={setAlerts} setAlertDetails={setAlertDetails} />
          <CounterpartySummary address={address} type='address' />
          <Card title='INVESTIGATION MODE' containerClassName='border border-gray-200'>
            <InvestigationTree originType='address' identifier={address.identifier} address={address} />
          </Card>
        </div>
      </Transition>
    </>
  );
};

export default Overview;
